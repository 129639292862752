import {
	AppAuthMethod,
	type AppConfig,
	type AppConfigByCountry,
	AppLanguage,
	ConsumerLoanProduct,
	CountryPhoneCode,
	NonLoanProduct,
	SupportedCountries,
} from '../types';
import { APP_COUNTRY } from './envs';

const APP_CONFIG_BY_COUNTRY_MAP = {
	[SupportedCountries.EE]: {
		authMethods: [
			AppAuthMethod.SMART_ID,
			AppAuthMethod.MOBILE,
			AppAuthMethod.ID_CARD,
			AppAuthMethod.PASSWORD,
		],
		termsUrlByLanguage: {
			[AppLanguage.EN]:
				'https://esto.eu/ee/teabe-kasutamise-tingimused?lang=en',
			[AppLanguage.ET]:
				'https://esto.eu/ee/teabe-kasutamise-tingimused?lang=et',
			[AppLanguage.RU]:
				'https://esto.eu/ee/teabe-kasutamise-tingimused?lang=ru',
			[AppLanguage.LV]:
				'https://esto.eu/ee/teabe-kasutamise-tingimused?lang=en',
			[AppLanguage.LT]:
				'https://esto.eu/ee/teabe-kasutamise-tingimused?lang=en',
		},
		premiumTermsUrlByLanguage: {
			[AppLanguage.EN]:
				'https://esto-public.s3.eu-west-2.amazonaws.com/docs/en-us/ESTO Premium terms.pdf',
			[AppLanguage.ET]:
				'https://esto-public.s3.eu-west-2.amazonaws.com/docs/et-ee/ESTO Premium Tingimused.pdf',
			[AppLanguage.RU]:
				'https://esto-public.s3.eu-west-2.amazonaws.com/docs/ru-ru/Условия ESTO Premium.pdf',
			[AppLanguage.LV]:
				'https://esto-public.s3.eu-west-2.amazonaws.com/docs/en-us/ESTO Premium terms.pdf',
			[AppLanguage.LT]:
				'https://esto-public.s3.eu-west-2.amazonaws.com/docs/en-us/ESTO Premium terms.pdf',
		},
		supportUrlByLanguage: {
			[AppLanguage.EN]:
				'https://esto.eu/ee/kliendile/kliendile-klienditugi?lang=en',
			[AppLanguage.ET]:
				'https://esto.eu/ee/kliendile/kliendile-klienditugi?lang=et',
			[AppLanguage.RU]:
				'https://esto.eu/ee/kliendile/kliendile-klienditugi?lang=ru',
			[AppLanguage.LV]:
				'https://esto.eu/ee/kliendile/kliendile-klienditugi?lang=en',
			[AppLanguage.LT]:
				'https://esto.eu/ee/kliendile/kliendile-klienditugi?lang=en',
		},
		starProduct: NonLoanProduct.CREDIT_LINE,
		legalInfo: {
			infoEmail: 'info@esto.ee',
			phone: {
				number: '(+372) 622 52 52',
				label: '+3726225252',
			},
			name: 'ESTO AS',
			address: 'Harju maakond, Tallinn, Laeva tn 2, 10111',
		},
		phoneCode: CountryPhoneCode.EE,
		isRejectedCAWRedirectionToCamEnabled: true,
		isStandingPaymentEnabled: true,
		creditLine: {
			isCreditAccountInterestFreeBannerEnabled: true,
			creditLineInterestFreeDays: 30,
		},
	},
	[SupportedCountries.LV]: {
		authMethods: [
			AppAuthMethod.SMART_ID,
			AppAuthMethod.PAYSERA_BANKLINK,
			AppAuthMethod.PASSWORD,
			AppAuthMethod.EPARAKSTS_MOBILE,
			AppAuthMethod.EPARAKSTS_SMARTCARD,
		],
		termsUrlByLanguage: {
			[AppLanguage.EN]: 'https://esto.eu/lv/noteikumi-un-nosacijumi?lang=en',
			[AppLanguage.ET]: 'https://esto.eu/lv/noteikumi-un-nosacijumi?lang=en',
			[AppLanguage.RU]: 'https://esto.eu/lv/noteikumi-un-nosacijumi?lang=ru',
			[AppLanguage.LV]: 'https://esto.eu/lv/noteikumi-un-nosacijumi?lang=lv',
			[AppLanguage.LT]: 'https://esto.eu/lv/noteikumi-un-nosacijumi?lang=en',
		},
		premiumTermsUrlByLanguage: {
			[AppLanguage.EN]:
				'https://esto-lv-public.s3.eu-central-1.amazonaws.com/docs/en-us/ESTO Premium terms.pdf',
			[AppLanguage.ET]:
				'https://esto-lv-public.s3.eu-central-1.amazonaws.com/docs/en-us/ESTO Premium terms.pdf',
			[AppLanguage.RU]:
				'https://esto-lv-public.s3.eu-central-1.amazonaws.com/docs/ru-ru/ESTO Premium условия.pdf',
			[AppLanguage.LV]:
				'https://esto-lv-public.s3.eu-central-1.amazonaws.com/docs/lv-lv/ESTO Premium Noteikumi.pdf',
			[AppLanguage.LT]:
				'https://esto-lv-public.s3.eu-central-1.amazonaws.com/docs/en-us/ESTO Premium terms.pdf',
		},
		supportUrlByLanguage: {
			[AppLanguage.EN]:
				'https://esto.eu/lv/klientiem/klientiem-atbalsts?lang=en',
			[AppLanguage.LV]:
				'https://esto.eu/lv/klientiem/klientiem-atbalsts?lang=lv',
			[AppLanguage.RU]:
				'https://esto.eu/lv/klientiem/klientiem-atbalsts?lang=ru',
			[AppLanguage.LT]:
				'https://esto.eu/lv/klientiem/klientiem-atbalsts?lang=en',
			[AppLanguage.ET]:
				'https://esto.eu/lv/klientiem/klientiem-atbalsts?lang=en',
		},
		starProduct: NonLoanProduct.CREDIT_LINE,
		legalInfo: {
			infoEmail: 'info@esto.lv',
			phone: {
				number: '(+371) 66 222 250',
				label: '+37166222250',
			},
			name: 'ESTO LV AS',
			address: 'Gustava Zemgala gatve 74, Rīga, LV-1039',
		},
		phoneCode: CountryPhoneCode.LV,
		isRejectedCAWRedirectionToCamEnabled: false,
		isStandingPaymentEnabled: false,
		creditLine: {
			isCreditAccountInterestFreeBannerEnabled: true,
			creditLineInterestFreeDays: 30,
		},
	},
	[SupportedCountries.LT]: {
		authMethods: [
			AppAuthMethod.SMART_ID,
			AppAuthMethod.MOBILE,
			AppAuthMethod.PAYSERA_BANKLINK,
			AppAuthMethod.PASSWORD,
		],
		termsUrlByLanguage: {
			[AppLanguage.EN]: 'https://esto.eu/lt/taisykles-ir-salygos?lang=en',
			[AppLanguage.ET]: 'https://esto.eu/lt/taisykles-ir-salygos?lang=en',
			[AppLanguage.RU]: 'https://esto.eu/lt/taisykles-ir-salygos?lang=ru',
			[AppLanguage.LV]: 'https://esto.eu/lt/taisykles-ir-salygos?lang=en',
			[AppLanguage.LT]: 'https://esto.eu/lt/taisykles-ir-salygos?lang=lt',
		},
		premiumTermsUrlByLanguage: {
			[AppLanguage.EN]:
				'https://esto-public.s3.eu-west-2.amazonaws.com/docs/en-us/ESTO Premium terms.pdf',
			[AppLanguage.ET]:
				'https://esto-public.s3.eu-west-2.amazonaws.com/docs/en-us/ESTO Premium terms.pdf',
			[AppLanguage.RU]:
				'https://esto-public.s3.eu-west-2.amazonaws.com/docs/ru-ru/Условия ESTO Premium.pdf',
			[AppLanguage.LV]:
				'https://esto-public.s3.eu-west-2.amazonaws.com/docs/en-us/ESTO Premium terms.pdf',
			[AppLanguage.LT]:
				'https://esto-lt-public.s3.eu-central-1.amazonaws.com/docs/lt-lt/ESTO+Premium+paslaugos+teikimo+tvarka.pdf',
		},
		supportUrlByLanguage: {
			[AppLanguage.EN]: 'https://esto.eu/lt/asmeninis/pagalba?lang=en',
			[AppLanguage.LT]: 'https://esto.eu/lt/asmeninis/pagalba?lang=lt',
			[AppLanguage.RU]: 'https://esto.eu/lt/asmeninis/pagalba?lang=ru',
			[AppLanguage.LV]: 'https://esto.eu/lt/asmeninis/pagalba?lang=en',
			[AppLanguage.ET]: 'https://esto.eu/lt/asmeninis/pagalba?lang=en',
		},
		starProduct: ConsumerLoanProduct.SMALL_LOAN,
		legalInfo: {
			infoEmail: 'info@estopay.lt',
			phone: {
				number: '(+370) 524 09 988',
				label: '+37052409988',
			},
			name: 'ESTO UAB',
			address: 'Lvivo g. 25, LT-09320 Vilnius, Lietuva',
		},
		phoneCode: CountryPhoneCode.LT,
		isRejectedCAWRedirectionToCamEnabled: true,
		isStandingPaymentEnabled: true,
		creditLine: {
			isCreditAccountInterestFreeBannerEnabled: true,
			creditLineInterestFreeDays: 30,
		},
	},
} as const satisfies AppConfigByCountry;

const appConfigByCountry =
	APP_CONFIG_BY_COUNTRY_MAP[APP_COUNTRY] ?? APP_CONFIG_BY_COUNTRY_MAP.EE;

export const APP_CONFIG = {
	name: 'ESTO',
	...appConfigByCountry,
} as const satisfies AppConfig;
