import App from '@app';
import React from 'react';
import ReactDOM from 'react-dom/client';

window.addEventListener('vite:preloadError', () => {
	window.location.reload(); // Reload the page on chunk load error
});

const root = document.getElementById('root');
if (!root) {
	throw new Error('Root element not found');
}

ReactDOM.createRoot(root).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
