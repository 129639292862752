import {
	APP_COUNTRY,
	RUDDERSTACK_DATA_PLANE_URL,
	RUDDERSTACK_WRITE_KEY,
} from '@config/envs';
import { rudderStackApi } from '@entities/analytics/api';
import { userApi } from '@entities/user';
import type { UserQuery } from '@entities/user/api/queries.gen';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { useQueryClient } from '@tanstack/react-query';
import { useSearch } from '@tanstack/react-router';
import { useCallback } from 'react';
import { useMount } from 'react-use';
import { useGetAnalyticsCampaignParameters } from './useGetAnalyticsCampaignParameters';

const rudderAnalytics = new RudderAnalytics();

export const useRudderStackAnalytics = () => {
	const queryClient = useQueryClient();
	const { rl_anonymous_id } = useSearch({ strict: false });
	const { mutateAsync } = rudderStackApi.useTrackRudderStackEventMutation();

	const campaignParameters = useGetAnalyticsCampaignParameters();

	const isRudderStackIsInitialized = Boolean(
		RUDDERSTACK_WRITE_KEY &&
			RUDDERSTACK_DATA_PLANE_URL &&
			rudderAnalytics.analyticsInstances[RUDDERSTACK_WRITE_KEY]?.initialized,
	);

	const identify = useCallback(() => {
		if (!isRudderStackIsInitialized) {
			return;
		}

		const data: Nullish<UserQuery> = queryClient.getQueryData(
			userApi.useSuspenseUserQuery.getKey(),
		);

		if (!data?.me?.id) {
			return;
		}

		const userId = `${APP_COUNTRY}-${data.me.id}`;

		rudderAnalytics.identify(userId);
	}, [isRudderStackIsInitialized, queryClient]);

	const initialize = () => {
		if (isRudderStackIsInitialized) {
			return;
		}

		rudderAnalytics.load(
			RUDDERSTACK_WRITE_KEY ?? '',
			RUDDERSTACK_DATA_PLANE_URL ?? '',
			{
				plugins: ['StorageEncryption'],
				storage: {
					encryption: {
						version: 'legacy',
					},
				},
			},
		);

		if (rl_anonymous_id) {
			rudderAnalytics.setAnonymousId(rl_anonymous_id);
		}

		rudderAnalytics.ready(() => {
			identify();
		});
	};

	const trackEvent = useCallback(
		({
			event,
			properties = {},
		}: {
			event: string;
			properties?: Record<string, string | number | boolean | Array<unknown>>;
		}) =>
			mutateAsync({
				event_name: event,
				properties: JSON.stringify(properties),
				context: {
					campaign: JSON.stringify(campaignParameters),
				},
			}),
		[mutateAsync, campaignParameters],
	);

	const trackPage = useCallback(
		(pageName: string) => {
			if (isRudderStackIsInitialized) {
				rudderAnalytics.page(pageName);
			}
		},
		[isRudderStackIsInitialized],
	);

	const reset = useCallback(() => {
		if (!isRudderStackIsInitialized) {
			return;
		}
		rudderAnalytics.reset();
	}, [isRudderStackIsInitialized]);

	useMount(() => {
		initialize();
	});

	return {
		reset,
		identify,
		trackEvent,
		trackPage,
	};
};
