import type { AppLanguage } from '@/shared/types';

export enum DealId {
	PHEROMONE = 'pheromone',
	IRRIGATOR = 'irrigator',
	CASEPRO = 'casepro',
	GOFIT = 'goFit',
	MOKYKLINE = 'mokykline',
	WOLT_EE = 'wolt-ee',
	WOLT_LV = 'wolt-lv',
	WOLT_LT = 'wolt-lt',
	UPGREAT_EE = 'upgreat-ee',
	UPGREAT_LV = 'upgreat-lv',
	UPGREAT_LT = 'upgreat-lt',
	FITLAP = 'fitlap',
	KULDAN = 'kuldan',
	MLILY_EE = 'mlily-ee',
	GIVEN_EE = 'given-ee',
	GOLDEN_LOMBARD_EE = 'golden-lombard-ee',
	MADRATSIPOOD_EE = 'madratsipood-ee',
	AIRSOFTGO_EE = 'airsoftgo-ee',
	ROBOTIMAAILM_EE = 'robotimaailm-ee',
	MSL_EE = 'msl-ee',
	MANGUARVUTID_EE = 'manguarvutid-ee',
	ROKOKO_LV = 'rokoko-lv',
	EKOPLANET_EE = 'ekoplanet-ee',
	EKOPLANET_LV = 'ekoplanet-lv',
	EKOPLANET_LT = 'ekoplanet-lt',
	ELIX_HEALTH_EE = 'elix-health-ee',
	STREET_STRIDER_EE = 'street-strider-ee',
	INDUSTREC_EE = 'industrec-ee',
	BILANCE = 'bilance',
	JUVEELISALONG_EE = 'juveelisalong-ee',
	D_ONE_EE = 'd-one-ee',
	IVO_NIKKOLO_EE = 'ivo-nikkolo-ee',
	MEREVARUSTUS_EE = 'merevarustus-ee',
	INPUIT_EE = 'inpuit-ee',
	ANIX_SHOP_EE = 'anix-shop-ee',
	LIGHT_CONCEPT_EE = 'light-concept-ee',
	ALEXANTO_EE = 'alexanto-ee',
	PROBIKE_LV = 'probike-lv',
	IVO_NIKKOLO_LT = 'ivo-nikkolo-lt',
	IVO_NIKKOLO_LV = 'ivo-nikkolo-lv',
	TOPCIK_LV = 'topcik-lv',
	SMARTPC_LT = 'smartpc-lt',
}

export type Deal = {
	id: DealId;
	brand: string;
	title: string;
	description: string;
	discountCode: string;
	discount: string;
	discountDescription?: string;
	imageSrc: string;
	websiteUrl: string | Record<AppLanguage, string>;
};
