import { KlixPaymentStatusType } from '@/shared/types';
import { RouteName } from '@config/routes';
import { AppPaymentMethod, paymentsApi } from '@entities/payments';
import { CreditCardPaymentStatus } from '@features/credit-card-payment';
import { AgreementPaymentFormView } from '@pages/agreement-payment/config';
import { createFileRoute, redirect } from '@tanstack/react-router';
import * as z from 'zod';

const AgreementSearchSchema = z.object({
	withPremium: z.boolean().optional().catch(false),
	paymentMethod: z
		.nativeEnum(AppPaymentMethod)
		.catch(AppPaymentMethod.BANKLINK),
	formView: z
		.nativeEnum(AgreementPaymentFormView)
		.optional()
		.catch(AgreementPaymentFormView.OVERVIEW),

	amount: z.number().optional().catch(undefined),

	referenceKey: z.string().optional().catch(undefined),
	agreementPaymentConfirm: z.boolean().optional().catch(undefined),

	// banklink
	session_id: z.string().optional().catch(undefined),
	hash: z.string().optional().catch(undefined),

	// credit card
	payment_status: z
		.nativeEnum(CreditCardPaymentStatus)
		.optional()
		.catch(undefined),
});

export const Route = createFileRoute('/_protected/payment/agreement/')({
	validateSearch: AgreementSearchSchema,
	beforeLoad: async ({
		search: { session_id, hash, payment_status },
		context: { queryClient },
	}) => {
		if (session_id && hash) {
			const data = await queryClient.fetchQuery({
				queryKey: paymentsApi.useKlixPaymentQuery.getKey({
					sessionId: session_id,
					hash,
				}),
				queryFn: paymentsApi.useKlixPaymentQuery.fetcher({
					sessionId: session_id,
					hash,
				}),
			});

			switch (data?.payment?.status) {
				case KlixPaymentStatusType.PAID: {
					throw redirect({
						to: RouteName.PAYMENT_AGREEMENT_SUCCESS,
						replace: true,
					});
				}
				case KlixPaymentStatusType.ERROR:
				case KlixPaymentStatusType.CANCELLED:
				case KlixPaymentStatusType.BLOCKED:
				case KlixPaymentStatusType.EXPIRED:
				case KlixPaymentStatusType.OVERDUE: {
					throw redirect({
						to: RouteName.PAYMENT_AGREEMENT_REJECT,
						replace: true,
					});
				}
				default: {
					throw redirect({
						to: RouteName.PAYMENT_AGREEMENT_PENDING,
						search: (search) => ({
							...search,
						}),
						replace: true,
					});
				}
			}
		}

		if (payment_status === CreditCardPaymentStatus.SUCCESS) {
			throw redirect({
				to: RouteName.PAYMENT_AGREEMENT_SUCCESS,
				replace: true,
			});
		}

		if (payment_status === CreditCardPaymentStatus.FAILED) {
			throw redirect({
				to: RouteName.PAYMENT_AGREEMENT_REJECT,
				replace: true,
			});
		}
	},
});
