/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type CardPaymentCalculationQueryVariables = Types.Exact<{
  amount: Types.Scalars['Float']['input'];
  paymentProvider: Types.Scalars['String']['input'];
}>;


export type CardPaymentCalculationQuery = { card_payment_fee?: { amount: number, amount_with_fee: number, card_payment_fee_pct: number } | null };

export type PaymentMethodsQueryVariables = Types.Exact<{
  countryCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  testMode?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  directPayment?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type PaymentMethodsQuery = { payment_methods?: Array<{ key: string, logo_url: string, name: string, type: string, iban?: string | null, beneficiary_name?: string | null, swift?: string | null } | null> | null };

export type KlixFragment = { id: number, status?: Types.KlixPaymentStatusType | null, klix_request_id: string, payment_url?: string | null };

export type KlixPaymentQueryVariables = Types.Exact<{
  sessionId: Types.Scalars['String']['input'];
  hash: Types.Scalars['String']['input'];
}>;


export type KlixPaymentQuery = { payment?: { id: number, status?: Types.KlixPaymentStatusType | null, klix_request_id: string, payment_url?: string | null } | null };


export const KlixFragmentDoc = `
    fragment Klix on KlixPayment {
  id
  status
  klix_request_id
  payment_url
}
    `;
export const CardPaymentCalculationDocument = `
    query CardPaymentCalculation($amount: Float!, $paymentProvider: String!) {
  card_payment_fee(amount: $amount, payment_provider: $paymentProvider) {
    amount
    amount_with_fee
    card_payment_fee_pct
  }
}
    `;

export const useCardPaymentCalculationQuery = <
      TData = CardPaymentCalculationQuery,
      TError = unknown
    >(
      variables: CardPaymentCalculationQueryVariables,
      options?: Omit<UseQueryOptions<CardPaymentCalculationQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CardPaymentCalculationQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CardPaymentCalculationQuery, TError, TData>(
      {
    queryKey: ['CardPaymentCalculation', variables],
    queryFn: fetcher<CardPaymentCalculationQuery, CardPaymentCalculationQueryVariables>(CardPaymentCalculationDocument, variables),
    ...options
  }
    )};

useCardPaymentCalculationQuery.getKey = (variables: CardPaymentCalculationQueryVariables) => ['CardPaymentCalculation', variables];

export const useSuspenseCardPaymentCalculationQuery = <
      TData = CardPaymentCalculationQuery,
      TError = unknown
    >(
      variables: CardPaymentCalculationQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<CardPaymentCalculationQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<CardPaymentCalculationQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<CardPaymentCalculationQuery, TError, TData>(
      {
    queryKey: ['CardPaymentCalculationSuspense', variables],
    queryFn: fetcher<CardPaymentCalculationQuery, CardPaymentCalculationQueryVariables>(CardPaymentCalculationDocument, variables),
    ...options
  }
    )};

useSuspenseCardPaymentCalculationQuery.getKey = (variables: CardPaymentCalculationQueryVariables) => ['CardPaymentCalculationSuspense', variables];


useCardPaymentCalculationQuery.fetcher = (variables: CardPaymentCalculationQueryVariables, options?: RequestInit['headers']) => fetcher<CardPaymentCalculationQuery, CardPaymentCalculationQueryVariables>(CardPaymentCalculationDocument, variables, options);

export const PaymentMethodsDocument = `
    query PaymentMethods($countryCode: String, $testMode: Boolean, $directPayment: Boolean) {
  payment_methods(
    country_code: $countryCode
    test_mode: $testMode
    direct_payment: $directPayment
  ) {
    key
    logo_url
    name
    type
    iban
    beneficiary_name
    swift
  }
}
    `;

export const usePaymentMethodsQuery = <
      TData = PaymentMethodsQuery,
      TError = unknown
    >(
      variables?: PaymentMethodsQueryVariables,
      options?: Omit<UseQueryOptions<PaymentMethodsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<PaymentMethodsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<PaymentMethodsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['PaymentMethods'] : ['PaymentMethods', variables],
    queryFn: fetcher<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, variables),
    ...options
  }
    )};

usePaymentMethodsQuery.getKey = (variables?: PaymentMethodsQueryVariables) => variables === undefined ? ['PaymentMethods'] : ['PaymentMethods', variables];

export const useSuspensePaymentMethodsQuery = <
      TData = PaymentMethodsQuery,
      TError = unknown
    >(
      variables?: PaymentMethodsQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<PaymentMethodsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<PaymentMethodsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<PaymentMethodsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['PaymentMethodsSuspense'] : ['PaymentMethodsSuspense', variables],
    queryFn: fetcher<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, variables),
    ...options
  }
    )};

useSuspensePaymentMethodsQuery.getKey = (variables?: PaymentMethodsQueryVariables) => variables === undefined ? ['PaymentMethodsSuspense'] : ['PaymentMethodsSuspense', variables];


usePaymentMethodsQuery.fetcher = (variables?: PaymentMethodsQueryVariables, options?: RequestInit['headers']) => fetcher<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, variables, options);

export const KlixPaymentDocument = `
    query KlixPayment($sessionId: String!, $hash: String!) {
  payment: get_klix_payment(session_id: $sessionId, hash: $hash) {
    ...Klix
  }
}
    ${KlixFragmentDoc}`;

export const useKlixPaymentQuery = <
      TData = KlixPaymentQuery,
      TError = unknown
    >(
      variables: KlixPaymentQueryVariables,
      options?: Omit<UseQueryOptions<KlixPaymentQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<KlixPaymentQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<KlixPaymentQuery, TError, TData>(
      {
    queryKey: ['KlixPayment', variables],
    queryFn: fetcher<KlixPaymentQuery, KlixPaymentQueryVariables>(KlixPaymentDocument, variables),
    ...options
  }
    )};

useKlixPaymentQuery.getKey = (variables: KlixPaymentQueryVariables) => ['KlixPayment', variables];

export const useSuspenseKlixPaymentQuery = <
      TData = KlixPaymentQuery,
      TError = unknown
    >(
      variables: KlixPaymentQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<KlixPaymentQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<KlixPaymentQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<KlixPaymentQuery, TError, TData>(
      {
    queryKey: ['KlixPaymentSuspense', variables],
    queryFn: fetcher<KlixPaymentQuery, KlixPaymentQueryVariables>(KlixPaymentDocument, variables),
    ...options
  }
    )};

useSuspenseKlixPaymentQuery.getKey = (variables: KlixPaymentQueryVariables) => ['KlixPaymentSuspense', variables];


useKlixPaymentQuery.fetcher = (variables: KlixPaymentQueryVariables, options?: RequestInit['headers']) => fetcher<KlixPaymentQuery, KlixPaymentQueryVariables>(KlixPaymentDocument, variables, options);
