import type { AppLanguage } from '@/shared/types';
import { VITE_ZENDESK_CHAT_KEY_CODE } from '@config/envs';
import i18n from 'i18next';
import { ZENDESK_DEPARTMENT_REGION_BY_LANGUAGES_MAP } from './config';

export const useZendeskWidget = () => {
	const changeZendeskLocale = (locale: string): void => {
		if (!VITE_ZENDESK_CHAT_KEY_CODE) return;

		window.zE('webWidget', 'setLocale', locale);
	};

	const setZendeskChatSelectSettings = (locale: string): void => {
		window.zE('webWidget', 'updateSettings', {
			webWidget: {
				chat: {
					departments: {
						select:
							ZENDESK_DEPARTMENT_REGION_BY_LANGUAGES_MAP[locale as AppLanguage],
					},
				},
			},
		});
	};

	const loadZendeskWidget = (locale = i18n.language): void => {
		if (!VITE_ZENDESK_CHAT_KEY_CODE) return;

		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.id = 'ze-snippet';
		script.async = true;
		script.src = `https://static.zdassets.com/ekr/snippet.js?key=${VITE_ZENDESK_CHAT_KEY_CODE}`;
		script.addEventListener('load', () => {
			changeZendeskLocale(locale);
			setZendeskChatSelectSettings(locale);
		});
		document.getElementsByTagName('body')[0].appendChild(script);
	};

	return {
		loadZendeskWidget,
		changeZendeskLocale,
	};
};
