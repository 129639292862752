import { AppLanguage } from '@/shared/types';

export enum ZendeskRegions {
	EN = 'EE',
	ET = 'EE',
	LV = 'LV',
	LT = 'LT',
	RU = 'RU',
}

export const ZENDESK_DEPARTMENT_REGION_BY_LANGUAGES_MAP = {
	[AppLanguage.EN]: ZendeskRegions.EN,
	[AppLanguage.ET]: ZendeskRegions.ET,
	[AppLanguage.LV]: ZendeskRegions.LV,
	[AppLanguage.LT]: ZendeskRegions.LT,
	[AppLanguage.RU]: ZendeskRegions.RU,
};
