import { createFileRoute } from '@tanstack/react-router';

import * as z from 'zod';

const AgreementPaymentPendingSearchSchema = z.object({
	session_id: z.string().optional().catch(undefined),
	hash: z.string().optional().catch(undefined),
});

export const Route = createFileRoute('/_protected/payment/agreement/pending')({
	validateSearch: AgreementPaymentPendingSearchSchema,
});
