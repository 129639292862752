export * from './auth';
export * from './auth-additional-info';
export * from './sidebar';
export * from './common';
export * from './dashboard';
export * from './agreements';
export * from './withdrawal';
export * from './errors';
export * from './offers';
export * from './error-screen';
export * from './loading-screen';
export * from './premium-page';
export * from './credit-line-balance-widget';
export * from './credit-line-page';
export * from './deals';
export * from './invoices';
export * from './payment';
export * from './invoice-payment';

export const LOCIZE_NAMESPACES = {
	auth: 'auth',
	authAdditionalInfo: 'auth-additional-info',
	sidebar: 'sidebar',
	common: 'common',
	dashboard: 'dashboard',
	agreements: 'agreements',
	agreementPayment: 'agreement-payment',
	withdrawal: 'withdrawal',
	errors: 'errors',
	offers: 'offers',
	errorScreen: 'error-screen',
	loadingScreen: 'loading-screen',
	premiumPage: 'premium-page',
	profilePage: 'profile-page',
	creditLinePage: 'credit-line-page',
	creditLineBalanceWidget: 'credit-line-balance-widget',
	deals: 'deals',
	invoices: 'invoices',
	invoicePayment: 'invoice-payment',
	payment: 'payment',
	gracePeriod: 'grace-period',
} as const;
